// Dependency
import React from "react"

// Components
import TrustBox from "./trustbox";

// Styles
import * as footerTrustStyles from "./footer-trustpilot.module.scss";

// Template
const FooterTrust = (props) => {

    return (
       <section className={footerTrustStyles.cdTrustFooterSection}>
            <div className="cd-max-width cd-xl-width">
                <TrustBox
                    locale={props.locale}
                    templateID={'53aa8912dec7e10d38f59f36'}
                    height={'140px'}
                    width={'100%'}
                />
            </div>
       </section>
    )

};

export default FooterTrust;